import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56')
];

export const server_loads = [];

export const dictionary = {
		"/(lms)/(app)": [9,[2,3]],
		"/(lms)/(app)/admin/activities": [10,[2,3,4]],
		"/(lms)/(app)/admin/activities/activity/[...activityId]": [11,[2,3,4]],
		"/(lms)/(app)/admin/activities/assign/[...id]": [12,[2,3,4]],
		"/(lms)/(app)/admin/activities/dashboard/[...activityId]": [13,[2,3,4]],
		"/(lms)/(app)/admin/activities/pathway/[...activityId]": [14,[2,3,4]],
		"/(lms)/(app)/admin/configuration": [15,[2,3]],
		"/(lms)/(app)/admin/content": [16,[2,3]],
		"/(lms)/(app)/admin/media": [17,[2,3]],
		"/(lms)/(app)/admin/notifications": [18,[2,3]],
		"/(lms)/(app)/admin/team-activities": [19,[2,3]],
		"/(lms)/(app)/admin/team-activities/[id]": [20,[2,3]],
		"/(lms)/(app)/admin/user-groups": [21,[2,3]],
		"/(lms)/(app)/admin/users": [22,[2,3]],
		"/(lms)/(app)/admin/users/imports": [23,[2,3]],
		"/(lms)/(app)/admin/users/[id]": [24,[2,3]],
		"/(lms)/(app)/assessments": [25,[2,3]],
		"/(lms)/(app)/assessments/review/[statementId]": [26,[2,3]],
		"/(lms)/(app)/assessments/[statementId]": [27,[2,3]],
		"/(published)/certificate/[statementId]": [54],
		"/(lms)/(app)/change-password": [28,[2,3]],
		"/(lms)/(app)/course/[sessionId]": [29,[5]],
		"/(lms)/(app)/cpd-tracker": [30,[2,3]],
		"/(lms)/(app)/documents/[statementId]": [31,[2,3]],
		"/(lms)/(app)/error": [32,[2,3]],
		"/(lms)/(public)/forgotten-password": [50,[2,7]],
		"/(lms)/(app)/fyscreate": [33,[2,3]],
		"/(lms)/(app)/fyscreate/admin/[id]": [34,[2,3]],
		"/(lms)/(app)/fyscreate/admin/[id]/preview": [35,[2,3,6]],
		"/(lms)/(public)/login": [51,[2,7]],
		"/(lms)/(public)/login/sso": [52,[2,7]],
		"/(lms)/(app)/marketplace": [36,[2,3]],
		"/(lms)/(app)/marketplace/[...id]": [37,[2,3]],
		"/(lms)/(app)/my-activities": [38,[2,3]],
		"/(lms)/(app)/mycourses/list": [39,[2,3]],
		"/(lms)/(app)/pathways/[[id]]": [40,[2,3]],
		"/(lms)/(app)/profile": [41,[2,3]],
		"/(published)/p/[id]": [55,[8]],
		"/(published)/render": [56],
		"/(lms)/(app)/reports": [42,[2,3]],
		"/(lms)/(app)/reports/builder": [43,[2,3]],
		"/(lms)/(app)/reports/builder/preview/[[id]]": [44,[2,3]],
		"/(lms)/(app)/reports/templates/[...name]": [45,[2,3]],
		"/(lms)/(app)/reports/[...id]": [46,[2,3]],
		"/(lms)/(public)/reset-password": [53,[2,7]],
		"/(lms)/(app)/terms": [47,[2,3]],
		"/(lms)/(app)/videos/[statementId]": [48,[2,3]],
		"/(lms)/(app)/[...content]": [49,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';