import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

const setEnvironment = (event) => {
	const platform = JSON.parse(sessionStorage[`tenant`] ?? `{}`)?.platformName;
	if (platform) {
		event.environment = platform;
	}
	return event;
};

Sentry.init({
	dsn: `https://de7cb50d775631c8cbcfbe6a42f5e322@o4507146419240960.ingest.de.sentry.io/4507146454237264`,
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [replayIntegration()],
	beforeSend: (event) => setEnvironment(event),
	beforeSendTransaction: (event) => setEnvironment(event)
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
